@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

select {
    -webkit-appearance: none;
}

* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
    display: none;  /* Chrome, Safari и Opera */
}

.rdrCalendarWrapper {
    border-radius: 5px;
}

.rdrDateDisplayWrapper {
    border-radius: 5px 5px 0 0; /* Верхние углы */
}

.rdrMonthAndYearWrapper {
    color: #fff;
    border-radius: 10px 10px 0 0; /* Верхние углы */
    padding: 5px; /* Пример добавления отступов */
}

.rdrDayToday .rdrDayNumber span:after {
    border: 2px solid #F39C12;
}
